import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../Page/Login";
import ProtectedRoute from "./SubComponents/ProtectedRoute";
import UnprotectedRoute from "./SubComponents/UnprotectedRoute";
import AI from "../Page/AI";
import SearchPage from "../Page/SearchPage";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/home" element={<ProtectedRoute />}>
                    <Route path="/home" element={<Navigate to="/ai" />}></Route>
                    <Route path="/home/*" element={<Navigate to="/ai" />}></Route>
                </Route>
                <Route path="/ai" element={<ProtectedRoute />}>
                    <Route path="/ai" element={<AI />}></Route>
                </Route>

                <Route path="/search" element={<SearchPage />}></Route>

                <Route path="/" element={<UnprotectedRoute />}>
                    <Route path="/" element={<Login />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
