import Page from "../../Component/Page/Page";
import styles from "./login.module.scss";
import { useNavigate } from "react-router-dom";
import { Credentials } from "../../Store/Credentials";
import { useState } from "react";

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleClick = async () => {
        var isAuthenticated = await Credentials.LogIn(username, password);
        if (isAuthenticated) navigate("/ai");
        else alert("Invalid credentials");
    };

    return (
        <Page showNav={false}>
            <div className={styles["login"]}>
                <form>
                    <div className="form-group">
                        <input
                            value={username}
                            type="text"
                            className="form-control"
                            id="username"
                            placeholder="Username"
                            onChange={(e) => {
                                setUsername(e.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <input
                            value={password}
                            type="password"
                            className="form-control"
                            id="pasword"
                            placeholder="Password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                    </div>
                    <button type="button" className="btn btn-primary mt-3" onClick={handleClick}>
                        Submit
                    </button>
                </form>
            </div>
        </Page>
    );
};

export default Login;
