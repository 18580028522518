import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import Select, { components } from "react-select";
import { AIItem, AI as API, ICategory } from "../../Api/AI";
import Page from "../../Component/Page/Page";
import useEffectWithInterval from "../../hook/useEffectWithInterval";
import { Credentials } from "../../Store/Credentials";
import styles from "./ai.module.scss";
import "react-datepicker/dist/react-datepicker.css";

const AI = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [batchAction, setBatchAction] = useState<"" | "add" | "change">("");
    const [aiList, setAiList] = useState<AIItem[]>([]);
    const [quantity, setQuantity] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [category, setCategory] = useState<ICategory | null>(null);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [batchActionCategory, setBatchActionCategory] = useState<ICategory | null>(null);
    const [totalpages, setTotalpages] = useState<number>(1);
    const [admin] = useState<number>(Credentials.admin);
    const [searchTerm, setSearchTerm] = useState("");
    const [delayedSearchTerm, setDelayedSearchTerm] = useState("");
    const [checkedProducts, setCheckedProducts] = useState<any>({});

    const loadCategories = useCallback(async () => {
        const categoriesFromApi = await API.Categories(delayedSearchTerm);

        setCategories(categoriesFromApi);

        return categoriesFromApi;
    }, [delayedSearchTerm]);

    useEffect(() => {
        loadCategories();
    }, [loadCategories]);

    const loadList = useCallback(async () => {
        setIsLoading(true);

        setAiList([]);
        const productData = await API.List(page, category?.id || null, delayedSearchTerm);
        setAiList(productData.list);
        setQuantity(productData.quantity);
        setTotalpages(Math.ceil(productData.quantity / 100));

        setIsLoading(false);
    }, [page, category, delayedSearchTerm]);

    useEffect(() => {
        setPage(1);
    }, [category]);

    useEffect(() => {
        setCheckedProducts({});
    }, [page, category, delayedSearchTerm]);

    useEffectWithInterval(() => setDelayedSearchTerm(searchTerm), [searchTerm], 1000);

    useEffectWithInterval(loadList, [loadList, page, category, delayedSearchTerm], 100);

    const selectedProductIds = useMemo(
        () =>
            Object.keys(checkedProducts)
                .map((productId) => (checkedProducts[productId] ? productId : ""))
                .filter((id) => !!id),
        [checkedProducts]
    );

    return (
        <Page showNav={true}>
            <div className={styles["orders"]}>
                {!batchAction && (
                    <>
                        <Select
                            className={classNames("m-1", styles["list-category"])}
                            value={category}
                            options={categories}
                            getOptionValue={(option) => option.id.toString()}
                            getOptionLabel={(option) => `${option.name} (${option.quantity})`}
                            isDisabled={!admin}
                            placeholder={"Filter Category"}
                            onChange={(newValue) => setCategory(newValue)}
                            isClearable
                        />
                        <div className={styles["list-header"]}>
                            <div>
                                Displaying {aiList.length} of {quantity} records.
                            </div>
                            <div className={styles["search-term"]}>
                                <input
                                    className={styles["search-input"]}
                                    placeholder="Search term"
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </>
                )}

                {selectedProductIds.length > 0 && (
                    <div className={styles["batch-actions"]}>
                        {!batchAction && (
                            <div>
                                <span>Batch actions:</span>
                                <button
                                    type="button"
                                    className={styles["link-button"]}
                                    onClick={() => {
                                        setBatchAction("change");
                                    }}
                                >
                                    Change category
                                </button>
                                <button
                                    type="button"
                                    className={styles["link-button"]}
                                    onClick={() => {
                                        setBatchAction("add");
                                    }}
                                >
                                    Add category
                                </button>
                                <button
                                    type="button"
                                    className={styles["link-button"]}
                                    onClick={() => {
                                        setCheckedProducts(
                                            aiList.reduce((a, v) => ({ ...a, [v.id]: true }), {})
                                        );
                                    }}
                                >
                                    Select all
                                </button>
                                <button
                                    type="button"
                                    className={styles["link-button"]}
                                    onClick={() => {
                                        setCheckedProducts(
                                            aiList.reduce((a, v) => ({ ...a, [v.id]: false }), {})
                                        );
                                    }}
                                >
                                    Clear all
                                </button>
                            </div>
                        )}
                        {!!batchAction && (
                            <div>
                                {batchAction === "change" && (
                                    <span>
                                        Choose the categories to <b>overwrite</b> on the items
                                        below:
                                    </span>
                                )}

                                {batchAction === "add" && (
                                    <span>
                                        Choose the categories to <b>add</b> to the items below:
                                    </span>
                                )}

                                <Select
                                    isClearable={false}
                                    className={classNames("m-1", styles["list-category"])}
                                    value={categories.filter(
                                        (category) => batchActionCategory?.id === category.id
                                    )}
                                    getOptionValue={(option) => option.id.toString()}
                                    getOptionLabel={(option) => `${option.name}`}
                                    options={categories}
                                    isDisabled={isLoading || admin !== 1}
                                    placeholder={"Select category"}
                                    onChange={async (newValue) => {
                                        if (newValue) setBatchActionCategory(newValue);

                                        return;
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className={styles["order-list"]}>
                    {aiList &&
                        aiList.map((product, index) => {
                            if (batchAction && !checkedProducts[product.id])
                                return <div key={product.id}></div>;

                            const MultiValueRemove = (props: any) => {
                                return <components.MultiValueRemove {...props} />;
                            };

                            return (
                                <div className={styles["order-row"]} key={product.id}>
                                    <div
                                        className={styles["form-check"]}
                                        onClick={() => {
                                            !batchAction &&
                                                setCheckedProducts({
                                                    ...checkedProducts,
                                                    [product.id]: !checkedProducts[product.id]
                                                });
                                        }}
                                    >
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultChecked={checkedProducts[product.id]}
                                            id="flexCheckDefault"
                                            disabled={!!batchAction}
                                        />
                                    </div>
                                    <div className={styles["product-image"]}>
                                        {product.name && (
                                            <div className={styles["product-image-wrapper"]}>
                                                <object
                                                    data={`https://file.llamastories.com/${product.asin}.jpg`}
                                                    type="image/png"
                                                >
                                                    <img
                                                        alt={"Product"}
                                                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    ></img>
                                                </object>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles["product-details"]}>
                                        <a
                                            target="_blank"
                                            href={`https://www.amazon.com/dp/${product.asin}`}
                                            rel="noreferrer noopener"
                                            title={product.name}
                                            className={styles["product-name"]}
                                        >
                                            {product.asin}
                                            {" - "}
                                            {product.name ||
                                                `${product.asin} - This item is no longer available`}
                                        </a>
                                        <div className={styles["inner-details"]}>
                                            {admin > 0 && (
                                                <div className={styles["order-by"]}>
                                                    <Select
                                                        isClearable={false}
                                                        className={classNames(
                                                            "m-1",
                                                            styles["list-category"]
                                                        )}
                                                        value={categories.filter(
                                                            (category) =>
                                                                product.category === category.id
                                                        )}
                                                        getOptionValue={(option) =>
                                                            option.id.toString()
                                                        }
                                                        getOptionLabel={(option) =>
                                                            `${option.name}`
                                                        }
                                                        components={{ MultiValueRemove }}
                                                        options={categories}
                                                        isDisabled={
                                                            isLoading ||
                                                            admin !== 1 ||
                                                            !!batchAction
                                                        }
                                                        placeholder={"Select category"}
                                                        onChange={async (newValue) => {
                                                            if (newValue) {
                                                                const newCategory = newValue.id;

                                                                setIsLoading(true);

                                                                const result =
                                                                    await API.UpdateCategory({
                                                                        productIds: [product.id],
                                                                        category: newCategory
                                                                    });

                                                                if (result) {
                                                                    if (
                                                                        category &&
                                                                        category?.id !== newCategory
                                                                    ) {
                                                                        setAiList(
                                                                            aiList.filter(
                                                                                (item) =>
                                                                                    item.id !==
                                                                                    product.id
                                                                            )
                                                                        );
                                                                    } else {
                                                                        product.category =
                                                                            newCategory;
                                                                    }
                                                                } else {
                                                                    alert(
                                                                        "Error updating category."
                                                                    );
                                                                }

                                                                setIsLoading(false);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                {batchAction ? (
                    <div>
                        <button
                            className="btn btn-primary m-1"
                            type="button"
                            onClick={async () => {
                                const categoryId = batchActionCategory?.id;

                                if (categoryId) {
                                    const productIds = selectedProductIds.map(
                                        (product) => +product
                                    );

                                    setIsLoading(true);

                                    let result = await API.UpdateCategory({
                                        productIds,
                                        category: categoryId
                                    });

                                    setBatchAction("");
                                    setBatchActionCategory(null);
                                    setIsLoading(false);

                                    if (result) {
                                        const categoriesFromApi = await loadCategories();
                                        if (categoryId) {
                                            const newCategory = categoriesFromApi.find(
                                                (foundCategory) => foundCategory.id === categoryId
                                            );
                                            if (newCategory) {
                                                setCategory(newCategory);
                                            }
                                        } else {
                                            loadList();
                                        }
                                    } else {
                                        alert("Error updating category.");
                                    }
                                }
                            }}
                            disabled={!batchActionCategory || isLoading}
                        >
                            Save
                        </button>
                        <button
                            className="btn btn-secondary m-1"
                            type="button"
                            onClick={() => {
                                setBatchAction("");
                                setBatchActionCategory(null);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                ) : (
                    <div className={styles["list-footer"]}>
                        <ReactPaginate
                            key={page}
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={({ selected }) => setPage(selected + 1)}
                            initialPage={page - 1}
                            pageRangeDisplayed={2}
                            pageCount={totalpages}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName={classNames("pagination", styles["pagination"])}
                            activeClassName="active"
                        />
                    </div>
                )}
            </div>
        </Page>
    );
};

export default AI;
