import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { AIItem, AI as API, ICategory } from "../../Api/AI";
import Page from "../../Component/Page/Page";
import useEffectWithInterval from "../../hook/useEffectWithInterval";
import styles from "./SearchPage.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { useSearchParams } from "react-router-dom";

const SearchPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [aiList, setAiList] = useState<AIItem[]>([]);
    const [quantity, setQuantity] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [category, setCategory] = useState<ICategory | null>(null);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [totalpages, setTotalpages] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchParams] = useSearchParams();

    const loadCategories = useCallback(async () => {
        const token = searchParams.get("t");
        if (token) {
            const categoriesFromApi = (await API.CategoriesForSearch(token))
                .filter((category) => category.quantity > 0)
                .sort((a, b) => (a.quantity > b.quantity ? -1 : 1));

            setCategories(categoriesFromApi);

            return categoriesFromApi;
        }
    }, [searchParams]);

    useEffect(() => {
        loadCategories();
    }, [loadCategories]);

    const loadList = useCallback(async () => {
        const token = searchParams.get("t");
        if (token) {
            setIsLoading(true);

            setAiList([]);
            const productData = await API.ListForSearch(page, category?.id || null, token);
            setAiList(productData.list);
            setQuantity(productData.quantity);
            setTotalpages(Math.ceil(productData.quantity / 100));

            setIsLoading(false);
        }
    }, [page, category, searchParams]);

    const loadSearchTerm = useCallback(async () => {
        const token = searchParams.get("t");
        if (token) {
            const apiSearch = await API.AISearch(token);
            if (apiSearch) {
                setSearchTerm(apiSearch.term);
            }
        }
    }, [searchParams]);

    useEffect(() => {
        setPage(1);
    }, [category]);

    useEffect(() => {
        loadSearchTerm();
    }, [loadSearchTerm]);

    useEffectWithInterval(loadList, [loadList, page, category], 100);

    return (
        <Page showNav={false}>
            {!isLoading && (
                <div className={styles["orders"]}>
                    <Select
                        className={classNames("m-1", styles["list-category"])}
                        value={category}
                        options={categories}
                        getOptionValue={(option) => option.id.toString()}
                        getOptionLabel={(option) => `${option.name} (${option.quantity})`}
                        placeholder={"Filter Category"}
                        onChange={(newValue) => setCategory(newValue)}
                        isClearable
                    />
                    <div className={styles["list-header"]}>
                        <div>
                            Displaying {aiList.length} of {quantity} records.
                        </div>
                        <div className={styles["search-term"]}>
                            <input
                                className={styles["search-input"]}
                                placeholder="Search term"
                                disabled
                                type="text"
                                value={searchTerm}
                            />
                        </div>
                    </div>

                    <div className={styles["order-list"]}>
                        {aiList &&
                            aiList.map((product) => {
                                return (
                                    <div className={styles["order-row"]} key={product.id}>
                                        <div className={styles["product-image"]}>
                                            {product.name && (
                                                <div className={styles["product-image-wrapper"]}>
                                                    <object
                                                        data={`https://file.llamastories.com/${product.asin}.jpg`}
                                                        type="image/png"
                                                    >
                                                        <img
                                                            alt={"Product"}
                                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                        ></img>
                                                    </object>
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles["product-details"]}>
                                            <a
                                                target="_blank"
                                                href={`https://www.amazon.com/dp/${product.asin}`}
                                                rel="noreferrer noopener"
                                                title={product.name}
                                                className={styles["product-name"]}
                                            >
                                                {product.asin}
                                                {" - "}
                                                {product.name ||
                                                    `${product.asin} - This item is no longer available`}
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    <div className={styles["list-footer"]}>
                        <ReactPaginate
                            key={page}
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={({ selected }) => setPage(selected + 1)}
                            initialPage={page - 1}
                            pageRangeDisplayed={2}
                            pageCount={totalpages}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName={classNames("pagination", styles["pagination"])}
                            activeClassName="active"
                        />
                    </div>
                </div>
            )}
        </Page>
    );
};

export default SearchPage;
