import { get, post, TResult } from "./BaseApi";

export interface AIItem {
    id: number;
    asin: string;
    name: string;
    category: number;
}

interface IList {
    list: AIItem[];
    quantity: number;
}

export interface ICategory {
    id: number;
    name: string;
    quantity: number;
}

export interface IProductToUpdateCategory {
    productIds: number[];
    category: number;
}

export interface IAISearch {
    guid: string;
    term: string;
    date: Date;
}

class AIApi {
    public async List(page: number, category: number | null, searchTerm: string): Promise<IList> {
        const apiResult = await get<TResult<IList>>(
            `catalog?category=${category === null ? "" : category}&page=${
                page === null ? "" : page
            }&searchterm=${searchTerm}`
        );

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                quantity: 0
            };
        }
    }

    public async ListForSearch(
        page: number,
        category: number | null,
        guid: string
    ): Promise<IList> {
        const apiResult = await get<TResult<IList>>(
            `catalog/listForSearch?category=${category === null ? "" : category}&page=${
                page === null ? "" : page
            }&t=${guid}`
        );

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                quantity: 0
            };
        }
    }

    public async UpdateCategory(products: IProductToUpdateCategory): Promise<boolean> {
        const apiResult = await post<TResult<string>>(`catalog/updateProductCategory`, {
            productIds: products.productIds,
            category: products.category
        });

        if (apiResult.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    public async Categories(searchterm: string): Promise<ICategory[]> {
        const apiResult = await get<TResult<ICategory[]>>(
            `catalog/categories?searchterm=${searchterm}`
        );

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return [];
        }
    }

    public async CategoriesForSearch(guid: string): Promise<ICategory[]> {
        const apiResult = await get<TResult<ICategory[]>>(`catalog/categoriesForSearch?t=${guid}`);

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return [];
        }
    }

    public async AISearch(guid: string): Promise<IAISearch | undefined> {
        const apiResult = await get<TResult<IAISearch>>(`catalog/search/${guid}`);

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return undefined;
        }
    }
}

export const AI = new AIApi();
